<template>
  <div>
    <template v-for="(item, index) in invalidFeedBackList">
      <b-form-invalid-feedback
        :key="index"
        :state="state"
        v-if="showInvalidFeedBack(item.key)"
      >
        {{
          $t(
            `fieldErrorMessage.${item.message}`,
            item.hasParams ? getValidationParams(item.key) : []
          )
        }}
      </b-form-invalid-feedback>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'AppFormInvalidFeedbackMessages',
    props: {
      validators: {
        type: Array,
        default: () => []
      },
      accept: {
        type: String,
        default: ''
      },
      videoFileState: {
        type: Boolean,
        default: true
      },
      validationValue: {
        type: Object,
        default: () => {}
      },
      validationParams: {
        type: Array,
        default: () => []
      },
      state: {
        type: Boolean,
        default: null
      }
    },
    data: () => ({
      invalidFeedBackList: [
        {
          key: 'required',
          message: 'requiredFieldDefault'
        },
        {
          key: 'numeric',
          message: 'invalidNumericFormat'
        },
        {
          key: 'url',
          message: 'invalidUrlFormat'
        },
        {
          key: 'bannerImageType',
          message: 'invalidBannerImageFormat',
          hasParams: true
        },
        {
          key: 'bannerVideoType',
          message: 'invalidBannerVideoFormat',
          hasParams: true
        },
        {
          key: 'fileSize',
          message: 'fileSizeExceeds',
          hasParams: true
        },
        {
          key: 'email',
          message: 'invalidEmailFormat'
        },
        {
          key: 'platformInfoApplicationType',
          message: 'invalidApplicationFormat',
          hasParams: true
        },
        {
          key: 'startDateTime',
          message: 'invalidStartDateTime'
        },
        {
          key: 'endDateTime',
          message: 'invalidEndDateTime'
        },
        {
          key: 'urlOptionalProtocol',
          message: 'invalidUrlOptionalProtocolFormat'
        },
        {
          key: 'startDate',
          message: 'invalidStartDate'
        },
        {
          key: 'endDate',
          message: 'invalidEndDate'
        },
        {
          key: 'startTime',
          message: 'invalidStartTime'
        },
        {
          key: 'endTime',
          message: 'invalidEndTime'
        },
        {
          key: 'bannerImageDimension',
          message: 'invalidImageDimensions',
          hasParams: true
        },
        {
          key: 'bannerVideoDimension',
          message: 'invalidVideoDimensions',
          hasParams: true
        },
        {
          key: 'minLength',
          message: 'requiredMinLength',
          hasParams: true
        },
        {
          key: 'hexColor',
          message: 'invalidColorFormat'
        }
      ]
    }),
    methods: {
      showInvalidFeedBack(name) {
        let show = false;

        if (name != 'bannerVideoDimension') {
          if (this.validators.includes(name)) {
            show = !this.validationValue[name];
          } else {
            show = false;
          }
        } else {
          let acceptArray = this.accept.split(',');
          // To cater show video dimension validation error message
          // due to normal input not able check video file dimension
          if (
            this.value &&
            this.videoFileState == false &&
            acceptArray.includes(this.value.type)
          ) {
            show = true;
          }
        }

        return show;
      },
      getValidationParams(key) {
        try {
          this.validationParams.find((x) => x.key == key).params;
        } catch (error) {
          debugger;
        }
        return this.validationParams.find((x) => x.key == key).params;
      }
    }
  };
</script>

<style></style>
