<template>
  <div>
    <!--begin::App Form Field-->
    <div>
      <label class="font-size-sm" v-if="!noTitle"
        >{{ title }} <span v-if="remark">({{ remark }})</span></label
      >
      <b-form-group class="mb-0">
        <div class="input-group">
          <div v-if="prepend" class="input-group-prepend">
            <slot name="prepend"> </slot>
          </div>
          <!--begin::Input Field-->
          <b-form-input
            v-if="formType == 'input'"
            :value="value"
            :state="state"
            :type="type"
            :size="size"
            class="form-control"
            :class="{ 'form-control-solid': solid }"
            :disabled="disabled"
            :readonly="readonly"
            :placeholder="placeholder"
            @input="input"
            @keyup="keyup"
          ></b-form-input>
          <!--end::Input Field-->

          <!--begin::Select Field-->
          <b-form-select
            v-if="formType == 'select'"
            :value="value"
            :state="state"
            :size="size"
            class="form-control"
            :class="{ 'form-control-solid': solid }"
            :options="options"
            :disabled="disabled"
            :text-field="textField"
            @input="input"
          ></b-form-select>
          <!--end::Select Field-->

          <!--begin::Multi Select Field-->
          <MultiSelect
            v-if="formType == 'multiselect'"
            multiple
            :value="value"
            :state="state"
            :placeholder="placeholder"
            :options="options"
            :disabled="disabled"
            :close-on-select="config.closeOnSelect"
            :clear-on-select="config.clearOnSelect"
            :custom-label="config.customLabel"
            :track-by="config.valueKey"
            :label="config.labelKey"
            :searchable="config.searchable"
            :allow-empty="config.allowEmpty"
            :taggable="config.taggable"
            :max="config.max"
            @input="input"
          ></MultiSelect>
          <!--end::Multi Select Field-->

          <!--begin::File Field-->
          <b-form-file
            v-if="formType == 'file'"
            :value="value"
            :state="
              config.isVideoFile
                ? videoFileState == false
                  ? videoFileState
                  : state
                : state
            "
            :accept="accept"
            :size="size"
            @input="input"
            @change="fileFieldChange"
            class="app-form-field__file-field d-inline-flex"
            :class="{ 'with-file-upload-button': fileUploadButton }"
          ></b-form-file>
          <b-button
            v-if="formType == 'file' && fileUploadButton"
            :variant="
              value
                ? state
                  ? 'success'
                  : 'light-danger'
                : state == null
                ? ''
                : 'light-danger'
            "
            :disabled="!value || !state"
            :class="{ 'cursor-default': !value || !state }"
            class="ml-4 d-inline-flex align-text-top align-items-center"
            @click="uploadFile"
          >
            <b-spinner v-if="isUploading" small></b-spinner>
            <i v-else class="flaticon-upload p-0"></i>
          </b-button>
          <!--end::File Field-->

          <!--begin::Text Area Field-->
          <b-form-textarea
            v-if="formType == 'textarea'"
            :value="value"
            :state="state"
            :size="size"
            class="form-control"
            :class="{ 'form-control-solid': solid }"
            :disabled="disabled"
            :rows="rows"
            :max-rows="maxRows"
            @input="input"
          ></b-form-textarea>
          <!--end::Text Area Field-->

          <!--begin::Active/Inactive Field-->
          <b-button
            v-if="formType == 'status'"
            :class="disabled ? 'cursor-default' : ''"
            :variant="`light-${value ? 'success' : 'danger'}`"
            :disabled="disabled"
            >{{ buttonText }}</b-button
          >
          <!--end::Active/Inactive Field-->

          <!--begin::Live Search Select Field-->
          <div
            v-if="formType == 'liveSearchSelect'"
            class="input-group"
            @click="openLiveSearchModal"
          >
            <div
              v-if="!disabled"
              class="input-group-prepend border-right pointer"
            >
              <span class="input-group-text"
                ><i class="flaticon2-search-1"></i
              ></span>
            </div>
            <b-form-input
              v-model="value"
              :state="state"
              disabled
              type="text"
              :size="size"
              class="form-control border-0"
              :class="[{ 'form-control-solid': solid }, { pointer: !disabled }]"
            ></b-form-input>

            <!--begin::Invalid Feedback Messages-->
            <AppFormInvalidFeedbackMessages
              :value="value"
              :state="state"
              :validators="validators"
              :accept="accept"
              :videoFileState="videoFileState"
              :validationParams="validationParams"
              :validationValue="validationValue"
            ></AppFormInvalidFeedbackMessages>
            <!--end::Invalid Feedback Messages-->
          </div>
          <!--end::Live Search Select Field-->

          <!--begin::Date Time Field-->
          <b-form-input
            v-if="formType == 'dateTime'"
            :value="value"
            :state="state"
            type="text"
            :size="size"
            class="form-control"
            :class="[
              { 'form-control-solid': solid },
              !disabled ? 'cursor-pointer' : 'cursor-default'
            ]"
            :disabled="disabled"
            readonly
            @click="showDateTimeModal = true"
          ></b-form-input>
          <!--end::Date Time Field-->

          <!--begin::Date Field-->
          <b-form-input
            v-if="formType == 'date'"
            :value="value"
            :state="state"
            type="text"
            :size="size"
            class="form-control"
            :class="[
              { 'form-control-solid': solid },
              !disabled ? 'cursor-pointer' : 'cursor-default'
            ]"
            :disabled="disabled"
            readonly
            @click="showDateModal = true"
          ></b-form-input>
          <!--end::Date Field-->

          <!--begin::Time Field-->
          <b-form-input
            v-if="formType == 'time'"
            :value="value"
            :state="state"
            type="text"
            :size="size"
            class="form-control"
            :class="[
              { 'form-control-solid': solid },
              !disabled ? 'cursor-pointer' : 'cursor-default'
            ]"
            :disabled="disabled"
            readonly
            @click="showTimeModal = true"
          ></b-form-input>
          <!--end::Time Field-->

          <!--begin::Hour Selection Field-->
          <b-form-input
            v-if="formType == 'hourSelection'"
            :value="value"
            :state="state"
            type="text"
            :size="size"
            class="form-control"
            :class="[
              { 'form-control-solid': solid },
              !disabled ? 'cursor-pointer' : 'cursor-default'
            ]"
            :disabled="disabled"
            readonly
            @click="showHourSelectionModal = true"
          ></b-form-input>
          <!--end::Hour Selection Field-->

          <!-- begin:: Radio Field -->
          <b-form-radio-group
            v-if="formType == 'radio'"
            :checked="value"
            :options="options"
            :plain="plain"
            :stacked="stacked"
            :size="size"
            :state="state"
            :disabled="disabled"
            @input="input"
          >
          </b-form-radio-group>
          <!-- end:: Radio Field -->

          <!-- begin:: Checkbox Field -->
          <b-form-checkbox
            v-if="formType == 'checkbox'"
            :class="className"
            :checked="value"
            :state="state"
            :switches="switches"
            :size="size"
            :disabled="disabled"
            @input="input"
          >
            <slot name="checkboxSlot" />
          </b-form-checkbox>

          <!--begin::Color Input Field-->
          <b-input-group class="app-form-field__color-input-group">
            <b-input-group-prepend v-if="formType == 'colorInput'">
              <b-form-input
                :value="value"
                :state="state"
                :size="size"
                class="app-form-field__color-input form-control w-100px border-right-0"
                :class="{ 'form-control-solid': solid }"
                :disabled="disabled"
                :readonly="readonly"
                type="color"
                @input="input"
                @keyup="keyup"
              ></b-form-input>
            </b-input-group-prepend>

            <b-form-input
              v-if="formType == 'colorInput'"
              :value="value"
              :state="state"
              :size="size"
              class="form-control"
              :class="{ 'form-control-solid': solid }"
              :disabled="disabled"
              :readonly="readonly"
              type="text"
              @input="input"
              @keyup="keyup"
            ></b-form-input>
          </b-input-group>
          <!--end::Color Input Field-->

          <!--begin::Invalid Feedback Messages-->
          <AppFormInvalidFeedbackMessages
            v-if="formType != 'liveSearchSelect'"
            :value="value"
            :state="state"
            :validators="validators"
            :accept="accept"
            :videoFileState="videoFileState"
            :validationParams="validationParams"
            :validationValue="validationValue"
          ></AppFormInvalidFeedbackMessages>
          <!--end::Invalid Feedback Messages-->
        </div>
      </b-form-group>
    </div>
    <!--end::App Form Field-->

    <!--begin::Live Search Modal-->
    <b-modal
      v-model="liveSearchModal.show"
      :title="title"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      centered
      @ok="okAction(liveSearchModal.itemChosen)"
      @cancel="cancelAction"
    >
      <div class="form-group">
        <div class="input-icon input-icon-right">
          <b-form-input
            v-model="liveSearchModal.searchValue"
            type="text"
            :placeholder="`${$t('label.search')}...`"
            class="form-control"
          ></b-form-input>
          <span
            v-if="liveSearchModal.searchValue"
            class="cursor-pointer"
            @click="liveSearchModal.searchValue = ''"
          >
            <i class="flaticon2-cross icon-md"></i>
          </span>
        </div>
        <span class="form-text text-muted">{{
          $t(`label.inputToSearchRecord`)
        }}</span>
      </div>

      <div class="max-h-300px overflow-y-scroll">
        <b-list-group>
          <template v-for="(item, index) in liveSearchModal.items">
            <b-list-group-item
              :key="index"
              button
              class="btn btn-hover-primary rounded-0"
              @click="liveSearchModal.itemChosen = item"
            >
              {{ item.displayName }}
            </b-list-group-item>
          </template>
          <b-list-group-item v-if="liveSearchModal.items.length < 1">
            {{
              `${$t('label.noResultsMatched')} "${liveSearchModal.searchValue}"`
            }}
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-modal>
    <!--end::Live Search Modal-->

    <!--begin::Date Time Modal-->
    <b-modal
      v-model="showDateTimeModal"
      no-close-on-esc
      hide-header
      centered
      size="sm"
      body-class="p-0"
      footer-class="p-1"
      @hide="dateTime.time && dateTime.date && dateTimeModalOKAction"
    >
      <b-tabs content-class="mt-3" fill>
        <b-tab :title="$t('label.date')" active>
          <b-calendar
            v-model="dateTime.date"
            hide-header
            block
            :date-format-options="{
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            }"
            :min="min"
            :max="max"
            label-help=""
            locale="en-US"
            class="m-2"
            @context="onDateContext"
          ></b-calendar>
        </b-tab>
        <b-tab :title="$t('label.time')">
          <div class="d-flex justify-content-center">
            <b-time
              v-model="dateTime.time"
              hide-header
              show-seconds
              locale="en-US"
              class="m-2"
              @context="onTimeContext"
            ></b-time>
          </div>
        </b-tab>
      </b-tabs>
      <template #modal-footer>
        <b-button variant="white" @click="dateTimeModalClearAction">
          {{ $t('label.clear') }}
        </b-button>
        <b-button
          :disabled="!dateTime.time || !dateTime.date"
          variant="primary"
          :class="{ 'cursor-default': !dateTime.time || !dateTime.date }"
          @click="dateTimeModalOKAction"
        >
          {{ $t('label.ok') }}
        </b-button>
      </template>
    </b-modal>
    <!--end::Date Time Modal-->

    <!--begin::Date Modal-->
    <b-modal
      v-model="showDateModal"
      no-close-on-esc
      hide-header
      centered
      size="sm"
      body-class="p-0"
      footer-class="p-1"
      @hide="dateModalOKAction"
    >
      <b-calendar
        v-model="date"
        hide-header
        block
        :date-format-options="{
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        }"
        label-help=""
        locale="en-US"
        class="m-2"
        @context="onDateContext"
      ></b-calendar>

      <template #modal-footer>
        <b-button variant="white" @click="dateModalClearAction">
          {{ $t('label.clear') }}
        </b-button>
        <b-button
          :disabled="!date"
          variant="primary"
          :class="{ 'cursor-default': !date }"
          @click="dateModalOKAction"
        >
          {{ $t('label.ok') }}
        </b-button>
      </template>
    </b-modal>
    <!--end::Date Modal-->

    <!--begin::Time Modal-->
    <b-modal
      v-model="showTimeModal"
      no-close-on-esc
      hide-header
      centered
      size="sm"
      body-class="p-0"
      footer-class="p-1"
      @hide="timeModalOKAction"
      modal-class="d-flex"
    >
      <b-time
        v-model="time"
        hide-header
        show-seconds
        locale="en-US"
        class="m-2"
        @context="onTimeContext"
      ></b-time>
      <template #modal-footer>
        <b-button variant="white" @click="timeModalClearAction">
          {{ $t('label.clear') }}
        </b-button>
        <b-button
          :disabled="!time"
          variant="primary"
          :class="{ 'cursor-default': !date }"
          @click="timeModalOKAction"
        >
          {{ $t('label.ok') }}
        </b-button>
      </template>
    </b-modal>
    <!--end::Time Modal-->

    <!--begin::Hour Selection Modal-->
    <b-modal
      v-model="showHourSelectionModal"
      no-close-on-esc
      hide-header
      centered
      size="sm"
      body-class="p-0"
      footer-class="p-1"
      @hide="hourSelectionModalOKAction"
      modal-class="d-flex"
    >
      <div class="d-flex justify-content-center px-8 py-4">
        <b-form-spinbutton
          v-model="hour"
          :min="1"
          :max="12"
          wrap
          vertical
          @input="hourSpinbuttonInput"
        ></b-form-spinbutton>

        <b-form-spinbutton
          v-model="optionAmPm"
          :formatter-fn="amPmFormatter"
          min="0"
          max="1"
          wrap
          vertical
          title="AM/PM"
          class="ml-2"
          @input="hourSpinbuttonInput"
        ></b-form-spinbutton>
      </div>
      <template #modal-footer>
        <b-button variant="white" @click="hourSelectionModalClearAction">
          {{ $t('label.clear') }}
        </b-button>
        <b-button
          :disabled="!hour || optionAmPm == null"
          variant="primary"
          :class="{ 'cursor-default': !hour || optionAmPm == null }"
          @click="hourSelectionModalOKAction"
        >
          {{ $t('label.ok') }}
        </b-button>
      </template>
    </b-modal>
    <!--end::Hour Selection Modal-->

    <!--begin::Video file input preview placeholder-->
    <video id="video-tag" class="d-none" src=""></video>
    <!--end::Video file input preview placeholder-->
  </div>
</template>

<script>
  import moment from 'moment';
  import AppFormInvalidFeedbackMessages from '@/views/components/bases/form-field/AppFormInvalidFeedbackMessages';

  const INITIAL_DATE = moment(new Date()).format('YYYY-MM-DD');
  const INITIAL_TIME = '23:59:59';
  const DEFAULT_START_DATE_TIME = new Date(
    new Date(new Date().setHours(0)).setMinutes(0)
  ).setSeconds(0);
  const DEFAULT_END_DATE_TIME = new Date(
    new Date(new Date().setHours(23)).setMinutes(59)
  ).setSeconds(59);

  export default {
    name: 'AppFormField',
    components: {
      AppFormInvalidFeedbackMessages
    },
    props: {
      value: {},
      state: {
        type: Boolean,
        default: null
      },
      title: {
        type: String,
        default: ''
      },
      formType: {
        type: String,
        default: 'input'
      },
      options: {
        type: Array,
        default: () => []
      },
      multiple: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      buttonText: {
        type: String,
        default: ''
      },
      accept: {
        type: String,
        default: ''
      },
      items: {
        type: Array,
        default: () => []
      },
      validationValue: {
        type: Object,
        default: () => {}
      },
      validators: {
        type: Array,
        default: () => []
      },
      solid: {
        type: Boolean,
        default: false
      },
      optional: {
        type: Boolean,
        default: false
      },
      textField: {
        type: String,
        default: 'text'
      },
      size: {
        type: String,
        default: 'md'
      },
      validationParams: {
        type: Array,
        default: () => []
      },
      noTitle: {
        type: Boolean,
        default: false
      },
      rows: {
        type: Number,
        default: 3
      },
      maxRows: {
        type: Number,
        default: 6
      },
      remark: {
        type: String,
        default: ''
      },
      fileUploadButton: {
        type: Boolean,
        default: false
      },
      isUploading: {
        type: Boolean,
        default: false
      },
      config: {
        type: Object,
        default: () => ({})
      },
      min: {
        default: null
      },
      max: {
        default: null
      },
      plain: {
        type: Boolean,
        default: false
      },
      stacked: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: ''
      },
      switches: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: 'text'
      },
      prepend: {
        type: Boolean,
        default: false
      },
      className: {
        type: String
      }
    },
    data: () => ({
      isMounted: false,
      showDateTimeModal: false,
      showDateModal: false,
      showTimeModal: false,
      showHourSelectionModal: false,
      date: '',
      time: '',
      dateTime: {
        date: '',
        time: ''
      },
      dateContext: '',
      timeContext: '',
      liveSearchModal: {
        show: false,
        title: '',
        searchValue: '',
        items: [],
        itemChosen: ''
      },
      invalidFeedBackList: [
        {
          key: 'required',
          message: 'requiredFieldDefault'
        },
        {
          key: 'numeric',
          message: 'invalidNumericFormat'
        },
        {
          key: 'url',
          message: 'invalidUrlFormat'
        },
        {
          key: 'bannerImageType',
          message: 'invalidBannerImageFormat',
          hasParams: true
        },
        {
          key: 'bannerVideoType',
          message: 'invalidBannerVideoFormat',
          hasParams: true
        },
        {
          key: 'fileSize',
          message: 'fileSizeExceeds',
          hasParams: true
        },
        {
          key: 'email',
          message: 'invalidEmailFormat'
        },
        {
          key: 'platformInfoApplicationType',
          message: 'invalidApplicationFormat',
          hasParams: true
        },
        {
          key: 'startDateTime',
          message: 'invalidStartDateTime'
        },
        {
          key: 'endDateTime',
          message: 'invalidEndDateTime'
        },
        {
          key: 'urlOptionalProtocol',
          message: 'invalidUrlOptionalProtocolFormat'
        },
        {
          key: 'startDate',
          message: 'invalidStartDate'
        },
        {
          key: 'endDate',
          message: 'invalidEndDate'
        },
        {
          key: 'startTime',
          message: 'invalidStartTime'
        },
        {
          key: 'endTime',
          message: 'invalidEndTime'
        },
        {
          key: 'bannerImageDimension',
          message: 'invalidImageDimensions',
          hasParams: true
        },
        {
          key: 'bannerVideoDimension',
          message: 'invalidVideoDimensions',
          hasParams: true
        }
      ],
      hourOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      hour: 1,
      amPms: ['AM', 'PM'],
      optionAmPm: 1,
      videoFileState: null
    }),
    watch: {
      items() {
        this.liveSearchModal.items = this.items;
      },
      'liveSearchModal.searchValue': function () {
        this.liveSearching(this.liveSearchModal.searchValue);
      },
      showDateTimeModal() {
        let value = this.value;
        let config = this.config;

        if (!value) {
          if (config.isStartDateTime) {
            value = DEFAULT_START_DATE_TIME;
          } else if (config.isEndDateTime) {
            value = DEFAULT_END_DATE_TIME;
          }
        }

        if (this.showDateTimeModal) {
          this.updateDateTime(value);
        }
      },
      showDateModal() {
        let value = this.value;

        if (value && this.showDateModal) {
          this.updateDate(value);
        }
      },
      showTimeModal() {
        let value = this.value;

        if (value && this.showTimeModal) {
          this.updateTime(value);
        }
      },
      showHourSelectionModal() {
        let value = this.value;

        if (this.showHourSelectionModal) {
          if (value) {
            this.updateHourSelectionValue(value);
          } else {
            this.setDefaultHourSelectionValue();
          }
        }
      }
    },
    mounted() {
      let vm = this;
      this.$nextTick(function () {
        vm.setIsMounted();
      });
    },
    methods: {
      setIsMounted() {
        let vm = this;
        setTimeout(() => {
          vm.isMounted = true;
        }, 1000);
      },
      keyup(e) {
        this.$emit('keyup', e);
      },
      radioGroupInput(value) {
        this.input(value);
      },
      fileFieldChange(event) {
        if (this.config.isVideoFile) {
          let videoTag = document.querySelector('#video-tag');
          let file = event.target.files[0];
          let params = this.validationParams.find(
            (x) => x.key == 'bannerVideoDimension'
          ).params;
          let standardHeight = params[0];
          let standardWidth = params[1];

          let videoDimensionValidation = async (file) => {
            try {
              await readUploadFileAsDataURL(file);

              videoTag.onloadedmetadata = () => {
                this.videoFileState =
                  videoTag.videoHeight >= standardHeight &&
                  videoTag.videoWidth >= standardWidth &&
                  videoTag.videoHeight == videoTag.videoWidth;
              };
            } catch (e) {
              console.warn(e.message);
            }
          };

          const readUploadFileAsDataURL = (file) => {
            if (file) {
              const reader = new FileReader();

              return new Promise((resolve, reject) => {
                reader.onerror = () => {
                  reader.abort();
                  reject(new DOMException('Problem parsing input file.'));
                };

                reader.onload = (e) => {
                  videoTag.src = e.target.result;
                  videoTag.load();
                  resolve(reader.result);
                };

                reader.readAsDataURL(file);
              });
            }
          };

          return videoDimensionValidation(file);
        }
      },
      updateHourSelectionValue(value) {
        let date = new Date(
          new Date().toISOString().substr(0, 10) + ' ' + value
        );
        this.hour = Number(moment(date).format('hh'));
        this.optionAmPm = Number(
          this.amPms.findIndex(
            (x) => x == moment(date).format('a').toUpperCase()
          )
        );
      },
      setDefaultHourSelectionValue() {
        let config = this.config;

        if (config.isStartHour) {
          this.hour = 12;
          this.optionAmPm = 0;
        } else if (config.isEndHour) {
          this.hour = 11;
          this.optionAmPm = 1;
        }
        this.hourSpinbuttonInput();
      },
      amPmFormatter(value) {
        return this.amPms[value];
      },
      hourSpinbuttonInput() {
        let valueAmPm = this.amPms[this.optionAmPm];
        let value = `${this.hour}:00:00 ${valueAmPm}`;

        if (!this.hour && !this.optionAmPm) {
          value = null;
        }

        this.input(value);
      },
      hourSelectionModalOKAction() {
        this.showHourSelectionModal = false;
      },
      hourSelectionModalClearAction() {
        this.hour = null;
        this.optionAmPm = null;
        this.input(null);
        this.showHourSelectionModal = false;
      },
      uploadFile() {
        this.$emit('upload-file');
      },
      input(value) {
        if (this.isMounted) this.$emit('input', value);
      },
      cancelAction() {
        this.resetLiveSearchModal();
      },
      okAction(value) {
        this.$emit('ok-action', value);
        this.resetLiveSearchModal();
      },
      resetLiveSearchModal() {
        this.liveSearchModal = {
          show: false,
          title: '',
          searchValue: '',
          items: [],
          itemChosen: ''
        };
      },
      liveSearching(value) {
        this.$emit('live-searching-action', value);
      },
      openLiveSearchModal() {
        if (!this.disabled) {
          this.$emit('open-live-search-modal-action');
          this.liveSearchModal.show = true;
        }
      },
      dateModalOKAction() {
        this.showDateModal = false;
      },
      dateModalClearAction() {
        this.date = '';
        this.input(this.date);
        this.showDateModal = false;
      },
      timeModalOKAction() {
        this.showTimeModal = false;
      },
      timeModalClearAction() {
        this.time = '';
        this.input(this.time);
        this.showTimeModal = false;
      },
      dateTimeModalOKAction() {
        let dt = this.dateTime;

        if (dt.date && !dt.time) {
          this.dateTime.time = INITIAL_TIME;
        } else if (!dt.date && dt.time) {
          this.dateTime.date = INITIAL_DATE;
        }

        this.showDateTimeModal = false;
      },
      dateTimeModalClearAction() {
        this.dateTime = {
          date: '',
          time: ''
        };
        this.showDateTimeModal = false;
      },
      updateDate(value) {
        if (value != '-') {
          this.date = moment(new Date(value)).format('YYYY-MM-DD');
        } else {
          this.date = '';
        }
      },
      updateTime(value) {
        let date = new Date(
          new Date().toISOString().substr(0, 10) + ' ' + value
        );

        this.time = moment(date).format('HH:mm:ss');
      },
      updateDateTime(value) {
        let newDateTime = moment(new Date(value));

        this.dateTime = {
          date: newDateTime.format('YYYY-MM-DD'),
          time: newDateTime.format('HH:mm:ss')
        };
      },
      inputDate() {
        let date = this.dateContext;

        if (date) this.input(date);
      },
      inputTime() {
        let time = this.timeContext;

        if (time) this.input(time);
      },
      inputDateTime() {
        let dateTime = '';

        if (this.dateContext && this.timeContext) {
          dateTime = this.dateContext + ' ' + this.timeContext;
        }

        this.input(dateTime);
      },
      onTimeContext(ctx) {
        this.timeContext = ctx.value ? ctx.formatted : '';

        switch (this.formType) {
          case 'dateTime':
            this.inputDateTime();
            break;
          case 'time':
            this.inputTime();
            break;
        }
      },
      onDateContext(ctx) {
        this.dateContext = ctx.selectedYMD
          ? moment(ctx.selectedYMD).format('DD MMM YYYY')
          : '';

        switch (this.formType) {
          case 'dateTime':
            this.inputDateTime();
            break;
          case 'date':
            this.inputDate();
            break;
        }
      }
    }
  };
</script>

<style lang="scss">
  .app-form-field__file-field {
    &.with-file-upload-button {
      width: 90% !important;
    }
  }

  .app-form-field__color-input-group {
    .app-form-field__color-input {
      border-radius: 0.42rem 0 0 0.42rem !important;
    }
  }

  span.multiselect__option {
    min-height: 40px !important;
    display: flex;

    span {
      align-self: center;
    }
  }

  .multiselect__tag-icon {
    top: 50%;
    bottom: 50%;
  }
</style>
